import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import RoutesPaths from "./routes/index";
import Error from "../Components/404/PageNotFound";

const Router = () => {
  const location = useLocation();
  const getItem = JSON.parse(localStorage.getItem("user"));
  const FinalRoute = (props) => {
    const route = props?.route;
    if (getItem?.Token === undefined && route?.meta?.authRoute) {
      return (
        <Navigate
          to="/login"
          replace={true}
          state={{ path: location?.pathname }}
        />
      );
    } else {
      return <route.component {...props} />;
    }
  };

  return (
    <>
      <Routes>
        {RoutesPaths.map((route, index) => {
          return (
            <Route
              exact
              key={index}
              path={route?.path}
              element={<FinalRoute route={route} />}
            />
          );
        })}
        <Route exact path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default Router;

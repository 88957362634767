import axios from "axios";
import { isUserLoggedIn } from "../util";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const Userdata = JSON.parse(isUserLoggedIn());

    if (Userdata?.Token) {
      config.headers = {
        Authorization: `Bearer ${Userdata.Token}`,
      };
    }
    // Check if the request is for file upload
    if (
      config.url &&
      (config.url === "/categories" ||
        config.url === "/banner" ||
        config.url.includes("/updateCategory/")) &&
      config.method === "post"
    ) {
      // Set headers for file upload
      config.headers = {
        ...config.headers,
        Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data",
      };
    } else {
      // Default headers for other requests
      config.headers = {
        ...config.headers,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default instance;

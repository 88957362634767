import toast from "react-hot-toast";

export const isUserLoggedIn = () => localStorage?.getItem("user");
export const isUserLoggedOut = () => localStorage?.clear();

// Common error handling function
export const handleErrors = (err) => {
  if (err) {
    const errorMessages = err.response.data;
    if (!errorMessages?.message?.includes("Invalid token.")) {
      toast.error(errorMessages.message);
    }
    // Check if the error message contains "Invalid token."
    if (
      errorMessages.message?.includes("Invalid token.") &&
      errorMessages.status === false
    ) {
      isUserLoggedOut();
    }
  }
};

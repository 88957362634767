export const EnglishConstant = {
  /* Signup and Login */
  Login: "You have Login successfully.",
  Email: "*Email address is required!",
  password: "*Password is required!",
  LanguageCode: "*Language Code is required!",
  LanguageName: "*Language Name is required!",
  Type: "Number not allow!",
  loggedout: "Logged Out",
  countryCode: "Country Code is required!",
  countryName: "Country Name is required!",
  currencyCode: "Currency Code is required!",
  phoneCode: "Phone Code is required!",
  Name: "Name  is required!",
  Image: "Image  is required!",
  Code: "Code  is required!",
  Title: "Title  is required!",
  Description: "Description  is required!",
  link: "link  is required!",
  Langue: "Langue  is required!",
  Status: "Status  is required!",
  Amount: "Amount  is required!",
  Note: "Note  is required!",
  duration: "duration  is required!",
  minutes: "minutes  is required!",
  tage: "tage  is required!",

  Body:"Body  is required!",
  Date:"Date is required!",
  Time:"Time is required!"

};

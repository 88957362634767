import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { EnglishConstant } from "../library/languages/englishMessages";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "../style";
import { headerdata,SIDEBAR_BOTTOM_LINKS} from "../Data/AllMapingData";
import Logo from "../img/venting_logo_h.png";
import { getToken } from "../Utils/util";

const linkClass =
	'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base'

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [OpenBar, setOpenBar] = useState(false);

  /*=============LOGOUT FUNCTION===========*/
  const handleogout = (e) => {
    e.preventDefault();
    localStorage.clear("user");
    navigate("/");
    toast.error(EnglishConstant.loggedout);
  };
  const userData = getToken();
  console.log('userData',userData)
  console.log('role',userData.role)
  console.log('headerdata',headerdata)
  return (
    <div className="bg-neutral-900 w-60 flex flex-col justify-between text-white">          
    <div className="z-50 ">
        <div className="flex items-center gap-2 px-1 py-3">
          <img src={Logo} alt="Logo" srcSet="" className="mx-auto w-12 h-12"/>
        </div>
        <div className="h-[calc(100vh-97px-107px)] overflow-y-auto">
          <ul className="py-8 flex flex-1 flex-col gap-0.5">
            {userData && headerdata
                ?.filter((data) => data?.roles?.includes(userData.role))
                .map((data, index) => (
                  <Link
                    to={data.pathname}
                    className={` ${
                      data.pathname === pathname
                        ? "bg-neutral-700 text-white"
                        : " text-neutral-400"
                    } flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base`}
                    key={index}
                  >
                    <li
                      to={data.pathname}
                      className="flex items-center gap-3 pl-7"
                    >
                      <div className="text-lg">{data.img}</div>
                      <span className="text-md">{data.pageName}</span>
                    </li>
                  </Link>
                ))}
          </ul>
        </div>
    
    </div>
    <div className="z-50">
   
        <div>
          <ul className="py-8 flex flex-1 flex-col gap-0.5">
            {userData &&
              SIDEBAR_BOTTOM_LINKS
                ?.filter((data) => data?.roles?.includes(userData.role))
                .map((data, index) => (
                  <Link
                    to={data.pathname}
                    className={` ${
                      data.pathname === pathname
                        ? "bg-neutral-700 text-white"
                        : " text-neutral-400"
                    } flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base`}
                    key={index}
                  >
                    <li
                      to={data.pathname}
                      className="flex items-center gap-3 pl-7"
                    >
                      <div className="text-lg">{data.img}</div>
                      <span className="text-xl">{data.pageName}</span>
                    </li>
                  </Link>
                ))}
          </ul>
        </div>
    
    </div>
   
  </div>
  );
}

export default SideBar;

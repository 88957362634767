const styles = {
  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",
  flexBetween: "flex justify-between items-center",
  model:
    "fixed h-modal h-full animated fadeInDown bg-[#000000bf]  overflow-auto z-50 inset-0",
  model1: "flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0",
  model2: "w-full bg-[#fafafb] rounded-2xl  md:mt-0 xl:p-0",
  closeX: "absolute right-6 top-5 cursor-pointer",
  input:
    "placeholder:text-thirdColor peer h-full w-full rounded-[7px] border border-t-transparent border-gray-200  bg-transparent px-3 py-2.5 font-sans text-sm font-normal  outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-gray-200 placeholder-shown:border-t-gray-200 focus:border-2 focus:border-primary focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100",
  inputLabel:
    "capitalize before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-thirdColor transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-thirdColor peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-primary peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-primary peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-thirdColor",

  btn: "rounded-md sm:px-6 px-1 py-2 font-medium  bg-white text-[#FF4D4F] cursor-pointer",
  btnSecond:
    "rounded-md sm:px-6 px-1  cursor-pointer py-1 font-medium text-white bg-primary",
  form: "px-5 py-5 border-primary border-2 rounded-xl shadow-lg md:max-w-sm w-full  text-opacity-70",
  formH1: "text-thirdColor font-bold text-3xl mb-1",
  formP: "text-base font-normal text-thirdColor mb-7",
  formI: "text-secondary absolute  top-2 left-2 ",
  formBTN:
    "block w-full bg-primary mt-4 py-2 rounded-2xl text-secondary font-semibold mb-2 ",
  editInput:
    "border rounded-md w-full  mx-auto border-border  p-2 outline-none",
};

export const layout = {
  section: `flex lg:flex-row flex-col gap-4 ${styles.paddingY}`,
  formInput: `py-10 w-full  px-5  ${styles.flexCenter}`,
};

export default styles;

import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import reduxthunk from "redux-thunk";
import rootReducer from "./Root-Reducer";

const middlewares = [reduxthunk];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;

import axios from "axios";
import { isUserLoggedIn } from "../util";

const instanceImage = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instanceImage.interceptors.request.use(
  (config) => {
    const Userdata = JSON.parse(isUserLoggedIn());

    if (Userdata?.Token) {
      config.headers.Authorization = `Bearer ${Userdata.Token}`;
    }
    config.headers.Accept = config.headers["Content-Type"] = "multipart/form-data";

    return config;
  },
  (error) => Promise.reject(error)
);

export default instanceImage;



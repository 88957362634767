const styles = {
  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",
  flexBetween: "flex justify-between items-center",
  model:
    "fixed h-modal h-full animated fadeInDown backdrop-filter backdrop-blur-sm bg-backdrop bg-gray-500 bg-opacity-25 overflow-auto z-50 inset-0",
  model1: "flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0",
  model2: "w-full  bg-white rounded-2xl  md:mt-0 xl:p-0",

  closeX: "absolute right-6 top-5 cursor-pointer",
  XMark: "fa-solid fa-xmark text-lg",
  inputDiv: "flex items-center border-2 py-0.5  rounded-xl mt-2",
  input: "input-bg  outline-none border-none w-full",
  btn: "border border-[#001039] rounded-md sm:px-6 px-1 py-2 font-medium text-lg group-hover:text-white group-hover:bg-gradient-to-r from-[#004585] to-[#000d35] transition-colors duration-700",
  btnsecound:
    "rounded-md sm:px-6 px-1  cursor-pointer py-2 font-medium sm:text-lg text-white  group-hover:text-[#001039]  border-[#001039] bg-gradient-to-r from-[#004585] to-[#000d35] group-hover:bg-gradient-to-r group-hover:from-transparent group-hover:to-transparent border group-hover:border-[#000d35] transition-colors duration-700",
  fileDownload:
    "border-t-2  hover:border-blue-900 border-r-2 border-l-2 rounded-2xl",
  fileType: "font-normal rounded-3xl py-1  cursor-pointer  overflow-auto",
  hoverPopup:
    "mx-auto  h-full border-2 border-solid  p-5 w-full max-w-xl hover:bg-opacity-70  shadow-lg hover:border-[#030239] border-blue-400 transition ease-in-out delay-150 hover:-translate-y-5 hover:scale-100 duration-700 hover:shadow-2xl rounded-xl",
  Contactus: "mb-8 flex w-full max-w-[370px] text-start",
  Contactus1:
    "bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]",
  form: "px-5 py-5 border-blue-900 border-2 rounded-xl shadow-lg md:max-w-sm w-full  text-opacity-70",
  formH1: "text-gray-800 font-bold text-3xl mb-1",
  formP: "text-base font-normal text-gray-600 mb-7",
  formI: "text-[#9098AE] absolute  top-3 left-2 ",
  formBTN:
    "block w-full bg-[#151f7c] mt-4 py-2 rounded-2xl text-white font-semibold mb-2 ",
  formLink: "text-sm ml-2 text-center cursor-pointer",
  ProfileSetIMG:"flex gap-2 rounded-2xl shadow-lg border border-blue-800 border-opacity-40 overflow-hidden w-36 h-36"
};

export const layout = {
  section: `flex lg:flex-row flex-col gap-4 ${styles.paddingY}`,
  forminput: `py-10 w-full  px-5  ${styles.flexCenter}`,
};

export default styles;

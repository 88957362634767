import { createContext, useContext, useEffect, useState } from "react";
import { getUsers, getcountries, getlanguage } from "../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from "../util";

const DataContext = createContext();
export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [getSingleUser, setgetSingleUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { users, countries, language } = useSelector((state) => state.data);
  const Userdata = JSON.parse(isUserLoggedIn());
  useEffect(() => {
    if (Userdata?.Token) {
      dispatch(getUsers()).finally(() => setLoading(false)); // Hide loader when data is fetched or failed
    }
  }, [dispatch, Userdata?.Token]);

  useEffect(() => {
    if (Userdata?.Token) {
      dispatch(getcountries()).finally(() => setLoading(false)); // Hide loader when data is fetched or failed
    }
  }, [dispatch, Userdata?.Token]);

  useEffect(() => {
    if (Userdata?.Token) {
      dispatch(getlanguage()).finally(() => setLoading(false)); // Hide loader when data is fetched or failed
    }
  }, [dispatch, Userdata?.Token]);

  const findUserById = async (userId) => {
    const user = users.find((user) => user._id === userId);
    if (user) {
      await setgetSingleUser(user);
    }
  };
  const values = {
    getSingleUser,
    setgetSingleUser,
    loading,
    users,
    findUserById,
    countries,
    language,
  };

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};
